@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #e0e0e0;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-style: normal;
  font-weight: 400;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  background: #19181d;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  position: relative;
  z-index: 0;
  margin-bottom: 200px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 100px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  position: relative;
  z-index: 24;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.chuumon {
  background: #a9a9a9;
}

.chuumon i {
  padding-left: 7px;
}

.chuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .chuumon a {
    padding: 16px;
  }
}

.saichuumon {
  background: #a9a9a9;
  border-left: 1px solid #fff;
}

.saichuumon i {
  padding-left: 7px;
}

.saichuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .saichuumon a {
    padding: 16px;
  }
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#header .flxR {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #header .flxR {
    width: 100%;
  }
}

#header .flxL {
  width: 14%;
  z-index: 12;
}

@media only screen and (max-width: 640px) {
  #header .flxL {
    width: 50%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .flxL {
    width: 35%;
    margin: 0 auto;
  }
}

#header .flxL #siteID {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID {
    display: none;
  }
}

#header .flxL #siteID img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID img {
    max-width: 100%;
  }
}

#header .subnav .left {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .subnav .left {
    border-top: 1px solid #fff;
    padding: 10px;
  }
}

#header .subnav .left span {
  display: block;
}

#header .subnav .left .tel2 {
  margin-right: 15px;
}

#header .subnav .left .tel2 a {
  color: #e0e0e0;
}

#header .subnav .left .tel3 a {
  color: #e0e0e0;
}

#header .subnav .left .tel4 a {
  color: #e0e0e0;
}

@media only screen and (max-width: 834px) {
  #header .subnav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  #header .subnav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: #000;
  }
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 30px;
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  #header .hedwrap {
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  #header .hedwrap {
    display: block;
    padding: 10px;
  }
}

@media only screen and (max-width: 640px) {
  #header {
    box-shadow: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header {
    box-shadow: none;
  }
}

#header #hedwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  #header #hedwrap {
    padding: 50px;
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #hedwrap {
    padding: 50px;
  }
}

#header #hedwrap .flxR {
  display: flex;
  align-items: center;
}

#header #hedwrap .flxR .subnav {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header #hedwrap .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100% !important;
    justify-content: space-between;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100% !important;
    justify-content: space-between;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .left {
    width: 35% !important;
  }
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .left {
    width: 35% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #hedwrap .flxR .subnav .left {
    width: 52% !important;
  }
}

#header #hedwrap .flxR .subnav .left .tel2 {
  color: #333;
  font-size: 26px;
  font-family: "Times New Roman";
  padding-right: 5px;
}

#header #hedwrap .flxR .subnav .left .tel2 img {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
  }
  #header #hedwrap .flxR .subnav .left .tel2 a {
    padding: 14px !important;
    display: block;
    color: #333 !important;
  }
  #header #hedwrap .flxR .subnav .left .tel2 span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    padding: 33px;
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header #hedwrap .flxR .subnav .left .tel2 {
    margin-right: 0;
    background: rgba(255, 255, 255, 0.5);
    padding: 33px;
  }
}

#header #hedwrap .flxR .subnav .left .tel2 a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

#header #hedwrap .flxR .subnav .left .tel2 i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

.right .cont {
  color: #fff;
  letter-spacing: 0.1em;
  transition: 1s;
  border: 1px solid #fff;
}

@media only screen and (max-width: 834px) {
  .right .cont {
    border: none;
    border-top: 1px solid #fff;
  }
}

.right .cont:hover {
  background: #2a5cae;
}

.right .cont a {
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 25px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.right .cont i {
  font-size: 20px;
  font-weight: 300;
  margin-right: 7px;
}

.right .cont span {
  font-size: 16px;
}

#nav_global {
  z-index: 10;
  position: relative;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

#nav_global ul li:first-of-type {
  display: none;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li:first-of-type {
    display: block;
  }
}

#nav_global ul li a {
  line-height: 1;
  color: #ffffff !important;
  display: block;
  padding: 10px 15px;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -10%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#nav_global ul ul li a {
  display: block;
  color: #000 !important;
}

#nav_global ul ul li:first-of-type {
  display: block;
}

@media only screen and (max-width: 834px) {
  #nav_global ul ul li:first-of-type {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #nav_global .pcnone {
    display: contents;
  }
  #nav_global .pcnone li:nth-of-type(1) a::before {
    content: "\f025" !important;
  }
  #nav_global .pcnone li:nth-of-type(2) a::before {
    content: "\f7a6" !important;
  }
}

.spnone {
  display: block;
}

.spnone li:first-of-type {
  display: block !important;
}

@media only screen and (max-width: 834px) {
  .spnone {
    display: none;
  }
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #fff;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #000;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    border-left: 1px solid #fff;
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    color: #000 !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    z-index: 1;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #333;
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 13px;
    content: "\f001";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 19px;
    content: "\f7a6";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 20px;
    content: "\f3c9";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    left: 23px;
    content: "\f3c9";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 17px;
    content: "\f140";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 17px;
    content: "\f2b5";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(8) a::before {
    left: 17px;
    content: "\f569";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(9) a::before {
    left: 17px;
    content: "\f1ad";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  z-index: -2;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 640px) {
  #mainArea img {
    width: 120% !important;
    min-height: 100vw !important;
    margin: 0 -34% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #mainArea img {
    width: 120% !important;
    min-height: 74vw !important;
    margin: 0 -34% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.maonwrap {
  position: relative;
}

.main_logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 28vw;
  min-width: 240px;
  height: 200px;
}

@media only screen and (max-width: 834px) {
  .main_logo {
    height: 40px;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  font-size: 40px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  background: rgba(51, 51, 51, 0.1);
  padding: 150px 0;
  display: block;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pan1 {
  margin: 10px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #e0e0e0;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #e0e0e0;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  footer#global_footer {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  footer#global_footer {
    margin-bottom: 90px;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li a {
  color: #333333;
  font-family: 'Sorts Mill Goudy', serif;
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #ffffff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.address {
  margin-top: 20px;
}

.pagetop {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}

.pagetop a {
  padding: 20px 24px;
  border-radius: 50%;
  background: #cbd0d3;
  font-size: 20px;
  display: block;
  color: #ffffff;
}

.ftlogo {
  margin-top: 50px;
  font-size: 24px;
}

.small_bg {
  padding: 30px 0;
  background: #19181d;
}

.ft_nav {
  margin-bottom: 50px;
}

#footer {
  padding: 0;
}

#footer .tel2 {
  margin-bottom: 50px;
}

#footer .tel2 a {
  color: #333333;
}

#footer2 {
  color: #fff;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
  padding: 100px 10px;
}

#footer2 .inner2 {
  margin: 0 auto;
  max-width: 1160px;
}

#footer2 .inner2 .ttl {
  font-size: 2.1em;
  color: #e0e0e0;
  margin-bottom: 50px;
  font-family: "Times New Roman";
}

#footer2 dl {
  width: 48%;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 0;
}

@media only screen and (max-width: 640px) {
  #footer2 dl {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #footer2 dl:nth-of-type(2) {
    margin-top: 30px;
  }
}

#footer2 dl:nth-of-type(2) dt {
  background: #000000;
}

#footer2 dl .tel3 {
  margin-top: 15px;
}

#footer2 dl .tel4 {
  margin-top: 15px;
}

#footer2 dl dt {
  font-size: 25px;
  background: #be1a18;
  margin-bottom: 40px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  font-weight: 700;
  color: #fff;
  padding: 20px 10px;
}

#footer2 dl dd {
  width: 93%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer2 dl dd img {
  width: 100% !important;
}

.inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .inner {
    display: block;
  }
}

/* box */
.h2_01 h2 {
  font-size: 2.1em;
  color: #e0e0e0;
  font-family: "Times New Roman";
  position: relative;
  margin-bottom: 1em;
}

.h2_01 h2:before {
  content: '';
  position: absolute;
  bottom: -30px;
  display: inline-block;
  width: 70px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #be1a18;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 1.7em;
  }
}

.h2_02 h2 {
  font-size: 2.1em;
  color: #e0e0e0;
  font-family: "Times New Roman";
  position: relative;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 1.7em;
  }
}

.h2_03 h2 {
  font-size: 2.1em;
  color: #e0e0e0;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

@media only screen and (max-width: 834px) {
  .h2_03 h2 {
    font-size: 1.7em;
  }
}

.h3_01 article > h3 {
  font-size: 1.56em;
  color: #e0e0e0;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
}

.sec_01 .table_btn {
  width: 44%;
}

@media only screen and (max-width: 640px) {
  .sec_01 .table_btn {
    width: 100%;
  }
}

.sec_01 .table_btn article tr:nth-of-type(1) td {
  padding: 0 !important;
}

.sec_01 .table_btn article tr:nth-of-type(3) {
  display: flex;
  justify-content: flex-end;
}

.sec_01 .table_btn .td_txt {
  padding: 20px 0  15px !important;
}

.sec_01 .btn a {
  color: #e0e0e0;
  font-family: "Times New Roman";
  display: block;
}

.sec_01 .btn > div {
  border-bottom: 1px solid #e0e0e0 !important;
  padding-bottom: 10px;
  width: 115px !important;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  max-height: 740px;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxR {
  background: #000000;
  padding: 75px 50px;
  width: 60%;
  transform: translate(-66%, -75%);
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR {
    transform: none;
    padding: 30px;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sec_03:before {
  content: "";
  width: 64%;
  height: 20%;
  background: #000;
  background-size: 5em;
  display: block;
  position: absolute;
  left: 0;
  z-index: -2;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
}

.sec_03 .flxL {
  width: 46%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxL {
    width: 100%;
  }
}

.sec_03 .flxR {
  width: 46%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxR {
    width: 100%;
  }
}

.moviebox {
  justify-content: space-between;
}

.moviebox .box {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .moviebox .box {
    width: 100%;
    margin: 10px;
  }
}

.listwrap:before {
  content: "";
  width: 80%;
  height: 20%;
  background: #000;
  background-size: 5em;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}

.owl-carousel {
  display: block !important;
}

.owl-carousel .owl-stage-outer {
  overflow: inherit !important;
  flex-direction: inherit !important;
}

.list {
  flex-wrap: unset !important;
}

.list .box h3 {
  text-align: center;
  font-size: 1.56em;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  margin-top: 30px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .list .box h3 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 640px) {
  .list .box h3 {
    font-size: 0.8em;
  }
}

.list article > div {
  display: none;
}

.table_btn td {
  border: none !important;
}

.table_btn:nth-of-type(1) article > div {
  background: #BE1A18;
  padding: 30px;
  font-size: 1.56em;
  font-family: "Times New Roman";
}

.table_btn:nth-of-type(2) article > div {
  background: #636363;
  padding: 30px;
  font-size: 1.56em;
  font-family: "Times New Roman";
}

.imgbox {
  justify-content: space-between;
}

.imgbox:before {
  content: "";
  width: 63%;
  height: 14%;
  background: #000;
  background-size: 5em;
  display: block;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 15%;
}

.imgbox .box {
  position: relative;
  width: 32%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin: 10px;
  }
}

.imgbox .box img {
  width: 100% !important;
}

.imgbox .box h3 {
  display: none;
}

.imgbox .box .kakomi {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 175px;
  min-width: 60px;
  height: 30px;
  color: #fff;
  font-size: 1.56em;
  text-align: center;
  white-space: nowrap;
}

.imgbox .box .kakomi a {
  color: #fff;
  display: block;
}

.imgbox .box article > div:first-child {
  display: none;
}

.h3_01 h3 {
  font-size: 1.4em;
  color: #333333;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 2px solid #dcdcdc;
  padding: 10px;
}

.txt {
  line-height: 2em;
}

.img_table td {
  padding: 0 !important;
}

.img_table .td_T {
  border: none !important;
  padding-bottom: 10px !important;
}

.img_table .td_T img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .img_table .td_T {
    text-align: right !important;
  }
}

.img_table .td_B {
  border: none !important;
}

.img_table .td_B img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .img_table .td_B {
    text-align: right !important;
  }
}

.img_table .td_L {
  border: none !important;
  padding-right: 20px !important;
}

.img_table .td_L img {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.instagram .sns_text {
  display: none;
}

.instagram div.sns_list {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  border: none !important;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list {
    justify-content: flex-start;
  }
}

.instagram div.sns_list > div {
  width: 20% !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list > div {
    width: 48% !important;
    margin: 5px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram div.sns_list > div {
    width: 33% !important;
  }
}

.instagram div.sns_list div.sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram .sns_photo img {
  width: 240px !important;
  height: 240px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .instagram .sns_photo img {
    height: 100% !important;
    width: 100% !important;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  position: relative;
  width: 49%;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    height: 200px;
  }
}

.bnrbox .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
  min-width: 60px;
  height: 25px;
  color: #ffffff;
  font-size: 30px;
  font-family: 'Sorts Mill Goudy', serif;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .bnrbox .box span {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 640px) {
  .bnrbox .box span {
    height: 40px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box span {
    width: 140px;
    font-size: 1.3em;
  }
}

.bnrbox .box h3 {
  display: none;
}

.bnrbox2 {
  justify-content: space-between;
}

.bnrbox2 .box {
  position: relative;
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 100%;
    height: 200px;
  }
  .bnrbox2 .box:nth-of-type(2) {
    margin-top: 30px;
  }
  .bnrbox2 .box:nth-of-type(3) {
    margin-top: 30px;
  }
}

.bnrbox2 .box span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 175px;
  min-width: 60px;
  height: 25px;
  color: #ffffff;
  font-size: 30px;
  font-family: 'Sorts Mill Goudy', serif;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .bnrbox2 .box span {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 .box span {
    width: 125px;
    font-size: 1.3em;
  }
}

.bnrbox2 h3 {
  display: none;
}

@media only screen and (max-width: 834px) {
  .newswrap {
    margin: 0 10px;
  }
}

.newswrap .news {
  height: 320px;
  overflow-y: scroll;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #ffffff;
}

.newswrap .news dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  text-align: left !important;
}

.newswrap .news dd {
  width: 100% !important;
  border: none !important;
  font-size: 0.8em;
  text-align: left !important;
}

.newswrap .news dl {
  display: block !important;
  border-bottom: 1px dashed #dedfda !important;
  padding: 20px 0 !important;
}

.top_blog {
  height: 320px;
  overflow-y: scroll;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.top_blog .blog_photo {
  display: none;
}

.top_blog .blog_list {
  border: none !important;
}

.top_blog h3 {
  display: none;
}

.top_blog .detail {
  color: #ffffff;
  font-size: 14px;
  margin-top: 0;
  text-align: left;
}

div.blog_list div.blog_date {
  font-size: 16px !important;
  color: #fff !important;
}

div.blog_list h3 {
  margin-bottom: 0;
}

div.blog_list div.blog_text {
  padding: 10px 0 !important;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.txt {
  line-height: 2em;
}

.h3_02 h3 {
  font-size: 1.4em;
  color: #e0e0e0;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 2px solid #dcdcdc;
  padding: 10px;
  background: #808080;
}

.mail a {
  color: #e0e0e0;
  border: 1px solid #e0e0e0;
  padding: 10px;
  display: block;
  max-width: 250px;
}

.mail span {
  display: flex;
}

.imgbox3 {
  justify-content: center;
}

.imgbox3 .box {
  width: 31%;
  margin: 10px;
}

@media only screen and (max-width: 640px) {
  .imgbox3 .box {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox3 .box {
    width: 46%;
  }
}

.imgbox3 .box img {
  width: 100% !important;
}

.imgbox3 .box > div {
  background: #000;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.imgbox4 {
  justify-content: flex-start;
}

.imgbox4 .box {
  width: 31%;
  text-align: center;
  margin: 10px;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .imgbox4 .box {
    width: 30%;
  }
}

.imgbox4 .box img {
  width: 350px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox4 .box {
    width: 46%;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox4 .box {
    width: 100%;
  }
}

.imgbox4 .box h3 {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.2em;
  padding: 10px;
}

.imgbox4 article > div {
  display: none;
}

.cont_form dt {
  color: #000;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.cont_form dd {
  color: #000;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.movie_box .box {
  width: 30%;
  margin: 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .movie_box .box {
    width: 46%;
  }
}

@media only screen and (max-width: 640px) {
  .movie_box .box {
    width: 100%;
  }
}

.movie_box .box iframe {
  width: 100%;
}

.table_img td {
  border: none !important;
  padding: 10px !important;
}

.table_img td img {
  width: 100% !important;
}

.txt01 {
  border: 1px solid #e0e0e0;
  padding: 70px;
  font-size: 1.25em;
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.7em;
}

.txt02 {
  line-height: 1.7em;
}

.txt02 a {
  color: #e0e0e0;
}

.txt02 a:hover {
  text-decoration: underline;
}

.imgbox2 {
  justify-content: space-between;
}

.imgbox2 .box {
  width: 30%;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox2 .box {
    width: 46%;
  }
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100%;
  }
}

.imgbox2 .box h3 {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.2em;
  padding: 10px;
}

.imgbox2 article > div {
  display: none;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
}

.sec_05 .txt {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_05 .txt {
    width: 100% !important;
  }
}

.sec_05 .img {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_05 .img {
    width: 100% !important;
  }
}

.sec_06 {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
}

.sec_06 .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  .sec_06 .flxL {
    width: 100% !important;
  }
}

.sec_06 .flxR {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .sec_06 .flxR {
    width: 100% !important;
  }
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
}

.Twitter iframe {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .Twitter {
    text-align: center;
    margin-top: 30px;
  }
}

.owl-dots {
  text-align: center;
  margin: 50px 5px 0 5px;
}

.owl-dots:active span {
  background: #fff !important;
}

.owl-dots span {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  display: block !important;
  text-align: center;
  margin: 5px;
}

.owl-theme .owl-controls {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-controls .owl-nav [class*=owl-] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-controls .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

@media only screen and (max-width: 640px) {
  .gcalendar iframe {
    width: 100%;
  }
}

#anc01 {
  margin-top: -160px;
  padding-top: 160px;
}

.list2 li {
  float: none !important;
}

.table_01 td {
  text-align: center !important;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.gmap iframe {
  width: 100%;
}

.style_form th {
  background: #ffffe0;
  padding: 10px;
}

.style_form td {
  padding: 16px;
}

.midashi h2 {
  color: #fff;
  line-height: 2em;
  font-size: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.blogwrap {
  background: #fff;
  padding: 35px;
}

@media only screen and (max-width: 834px) {
  .blogwrap {
    margin: 0 10px;
  }
}

.Blog {
  height: 300px;
  overflow-y: scroll;
}

.Blog .blog_photo {
  display: none;
}

.Blog .detail {
  display: none;
}

.Blog div.blog_list {
  border: none;
}

.Blog .blog_text {
  font-size: 1em !important;
}

.Blog .blog_date {
  color: #333333 !important;
}

.Blog h3 a {
  color: #333333 !important;
}

.bg_100per_wrap {
  position: relative;
  padding: 290px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 530px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #dedfda;
  background-size: cover;
  z-index: 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.bg_100per_wrap2 {
  position: relative;
  padding: 80px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap2 {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #fff;
}

#TRANS_ANNOT {
  display: none;
}
